import React, { useMemo, useState } from 'react';
import {Box, Container, Typography, Rating,} from '@mui/material';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import useTexts from "../../hooks/useTexte";
import './Satisfaction.css';

export default function Satisfaction({ formData , setFormState , formState }) {
    const [hover, setHover] = useState({});
    const {t} = useTexts();

    const labels = useMemo(() => {
        return {
            1: t('RATING_1'),
            2: t('RATING_2'),
            3: t('RATING_3'),
            4: t('RATING_4'),
            5: t('RATING_5'),
        };
    }, [t]);

    const getLabelText = (value) => {
        return value !== null ? labels[value] : '';
    }

    const handleRatingChange = (fieldName, newValue) => {
        setFormState((prev) => ({ ...prev, [fieldName]: newValue }));
    };

    const handleHoverChange = (fieldName, newHover) => {
        setHover((prev) => ({ ...prev, [fieldName]: newHover }));
    };

    return (
        <Container maxWidth="md" sx={{ mt: 2 }} className='satisfaction-container'>
            {Array.isArray(formData.sections) && formData.sections.map((section, sectionIndex) => {
                return (
                    <Box key={sectionIndex} className="satisfaction-section">
                        <Typography variant="h6" gutterBottom>
                            {section.title}
                        </Typography>
                        {Array.isArray(section.fields) && section.fields.map((field, fieldIndex) => {
                            const currentRating = formState[field.name] ?? 0;
                            const currentHover = hover[field.name] ?? -1;

                            return (
                                <div key={fieldIndex} className="satisfaction-rating">
                                    <Typography variant="body1" gutterBottom sx={{ mr: 1 }}>
                                        {field.label}
                                    </Typography>
                                    <div className='rating-box'>
                                        <Rating
                                            name={field.name}
                                            value={currentRating}
                                            getLabelText={getLabelText}
                                            icon={<StarRoundedIcon fontSize="inherit" />}
                                            emptyIcon={<StarBorderRoundedIcon fontSize="inherit" />}
                                            onChange={(event, newValue) => {
                                                handleRatingChange(field.name, newValue);
                                            }}
                                            onChangeActive={(event, newHover) => {
                                                handleHoverChange(field.name, newHover);
                                            }}
                                            max={5}
                                        />
                                        <Box sx={{ ml: 2 }}>
                                            {labels[currentHover !== -1 ? currentHover : currentRating] || ''}
                                        </Box>
                                    </div>
                                </div>
                            );
                        })}
                    </Box>
                );
            })}
        </Container>
    );
}
