import {Box, CircularProgress} from "@mui/material";
import React from "react";


const LoaderWait = React.memo(() => {
    return (
        <Box sx={{display: 'flex', justifyContent: 'center', p: 3}}>
            <CircularProgress/>
        </Box>
    );
})
export default LoaderWait