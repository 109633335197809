import {Grid} from "@mui/material";
import React from "react";
import AvisBox from "./AvisBox";

const AvisBoxGrid = ({ title, avis, type, haveChef, onClick, isPage, chefData }) => {
    const writor =  avis?.writor
    const isDifferentUser = avis?.writor?.user_id !== chefData?.user_id;

    return (
        <Grid item xs={12} sm={4}>
            <AvisBox
                isPage={isPage}
                title={title}
                avis={avis}
                type={type}
                onClick={haveChef ? onClick : null}
            />
            {haveChef && chefData && (
                <div style={{marginTop: "16px", padding: "10px", border: "1px solid #ddd", borderRadius: "8px"}}>
                    <h4 style={{marginBottom: "8px"}}>Chef associé</h4>
                    <p>
                        <strong>Prénom :</strong> {chefData.firstname}
                    </p>
                    <p>
                        <strong>Nom :</strong> {chefData.lastname}
                    </p>
                    <p>
                        <strong>Email :</strong> {chefData.email}
                    </p>
                    <p>
                        <strong>Code Sycomore :</strong> {chefData.code_sycomore}
                    </p>
                    <p>
                        <strong>Tel 1 :</strong> {chefData.phone}
                    </p>
                    <p>
                        <strong>Tel 2 :</strong> {chefData.phone_2}
                    </p>
                    <p>
                        <strong>Tel 3 :</strong> {chefData.phone_3}
                    </p>
                </div>
            )}
            {isDifferentUser && writor && (
                <div style={{
                    marginTop: "16px",
                    padding: "10px", border: "1px solid #ddd", borderRadius: "8px", background: "#f9f9f9"
                }}>
                    <h4 style={{marginBottom: "8px"}}>Auteur de l'avis</h4>
                    <p>
                        <strong>Prénom :</strong> {writor.firstname}
                    </p>
                    <p>
                        <strong>Nom :</strong> {writor.lastname}
                    </p>
                    <p>
                        <strong>Email :</strong> {writor.email}
                    </p>
                    <p>
                        <strong>Tel 1 :</strong> {writor.phone}
                    </p>
                    <p>
                        <strong>Tel 2 :</strong> {writor.phone_2}
                    </p>
                    <p>
                        <strong>Tel 3 :</strong> {writor.phone_3}
                    </p>
                </div>
            )}
        </Grid>
    );
};
export default AvisBoxGrid;