// src/composant/SearchModal.js
import React, {useEffect, useState} from 'react';

import {useFetchWithToken} from '../../hooks/useFetchWithToken'; // Assurez-vous que le chemin d'importation est correct
import { Drawer, IconButton, TextField, List, ListItem, ListItemText, Divider, Grid, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import SearchResult from "./SearchResult";
import './styles/Search.css';

const SearchModal = ({modal , open, onClose, onClick, functionMode,foreign }) => {
    const fetchWithToken = useFetchWithToken();
    const [searchSycomore, setSearchSycomore] = useState('');
    const [searchLastname, setSearchLastname] = useState('');
    const [searchFirstname, setSearchFirstname] = useState('');
    const [Chefs, setChefs] = useState([]);
    const handleSearch = async (e) => {

        if (searchSycomore  || searchLastname != "" || searchFirstname != ""    )
        {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/user/search`;
            const body = ({sycomore: searchSycomore, lastname: searchLastname, firstname: searchFirstname,
                foreign
            })
            const data = await fetchWithToken(apiUrl, 'POST', body);
            setChefs(data.chefs)
        }else{
            setChefs([])
        }
    }

    const handleSearchSycomore = async (e) => {
        setSearchSycomore(e.target.value);
    };
    const handleSearchLastName = async (e) => {
        setSearchLastname(e.target.value);
    };
    const handleSearchFirstName = async (e) => {
        setSearchFirstname(e.target.value);
    };
    useEffect(() => {
        setSearchSycomore("")
        setSearchFirstname("")
        setSearchLastname("")
    }, []);
    useEffect(() => {
        handleSearch();
    }, [searchSycomore, searchLastname, searchFirstname]);


    const content = (
        <>
            <Typography variant="h6" gutterBottom>
                Rechercher un Chef
            </Typography>
            <TextField
                label="Code Sycomore"
                value={searchSycomore}
                onChange={(e) => setSearchSycomore(e.target.value)}
                placeholder="Code Sycomore"
                fullWidth
                variant="outlined"
                margin="normal"
                InputProps={{
                    startAdornment: (
                        <SearchIcon position="start" />
                    ),
                }}
            />
            <TextField
                label="Nom de famille"
                value={searchLastname}
                onChange={(e) => setSearchLastname(e.target.value)}
                placeholder="Nom de famille"
                fullWidth
                variant="outlined"
                margin="normal"
                InputProps={{
                    startAdornment: (
                        <SearchIcon position="start" />
                    ),
                }}
            />
            <TextField
                label="Prénom"
                value={searchFirstname}
                onChange={(e) => setSearchFirstname(e.target.value)}
                placeholder="Prénom"
                fullWidth
                variant="outlined"
                margin="normal"
                InputProps={{
                    startAdornment: (
                        <SearchIcon position="start" />
                    ),
                }}
            />
            <Divider style={{ margin: '16px 0' }} />
            <div className='search-result-card-container'>
                {Chefs && Chefs.map((chef, index) => (
                    <SearchResult
                        key={index}
                        person={chef}
                        handleClick={onClick}
                        functionMode={functionMode}
                    />
                ))}
            </div>
        </>
    );
    return modal ? (
        <Drawer anchor="right" open={open} onClose={onClose}>
            <div style={{width: 300, padding: 16}}>
                {content}
            </div>
        </Drawer>
) : (
        <div>
            {content}
        </div>
    );
};

export default SearchModal;
