import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import {
    Button,
    Grid,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    TextField,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Card,
    CardContent,
    CardMedia,
    FormControlLabel,
    FormGroup, Checkbox, FormLabel, RadioGroup, Radio,
} from '@mui/material';
import { CloseRounded, PauseCircleOutlineRounded, PeopleAltRounded, RocketLaunchRounded } from '@mui/icons-material';
import CircularIcon from "@mui/icons-material/Description";
import ListIcon from "@mui/icons-material/List";

import { formatDate } from '../../Utils/functionTransforms';
import { branchOptions } from '../../Utils/BranchOption';
import { LevelOptions } from '../../Utils/LevelOption';
import { statuses } from "../../Utils/Status";

import { useFetchWithToken } from "../../hooks/useFetchWithToken"
import useTexts from "../../hooks/useTexte";
import useRoles from "../../hooks/useRoles";
import { useConfirm } from "../../Provider/ConfirmProvider";
import { useAuth } from "../../Context/AuthContext";

import FormationForm from "./FormationForm";
import StagiareCardAdmin from "./StagiareCardAdmin";
import SearchModal from "../Search/SearchModal";
import MoulinetteModal from "./MoulinetteModal";
import './styles/Formation.css'

const FormationAdminItem = ({ wish, index, functionOptions, addFormation, questionnaires, loadWishes }) => {
    const fetchWithToken = useFetchWithToken();
    const [filters, setFilters] = React.useState({
        status: {
            'WAIT': false,
            'WAIT_VALIDATION': false,
            'VALIDATE': false,

        },
        'hideInWait': 1,
        codeSycomore: '',
    });
    const {currentFunction, token} = useAuth();
    const [addOption, setAddOption] = React.useState('withoutValidate');

    const [searchOpen, setSearchOpen] = useState(false);
    const { requestConfirm } = useConfirm();
    const chefFunction = functionOptions.find(func => func.id == currentFunction);
    const roles = useRoles(chefFunction ? chefFunction.fonction : "");
    const isAdministrator = roles.includes('Administrator');
    const [openModalList, setOpenModalList] = useState(false);
    const [openModalListWait, setOpenModalListWait] = useState(false);

    const [editMode, setEditMode] = useState(false);
    const [updatedWish, setUpdatedWish] = useState({ ...wish });
    const [users, setUsers] = useState([]);
    const [usersWait, setUsersWait] = useState([]);

    const [openMoulinetteDialog, setOpenMoulinetteDialog] = useState(false);

    const { t } = useTexts()
    const [openResponsableModal, setOpenResponsableModal] = useState(false);
    const [openQuestionnaireModal, setOpenQuestionnaireModal] = useState(false);
    const [selectedResponsable, setSelectedResponsable] = useState('');
    const [selectedQuestionnaire, setSelectedQuestionnaire] = useState('');
    const [selectedFormationId, setSelectedFormationId] = useState(null);
    const navigate = useNavigate();
    const [launchAnimation, setLaunchAnimation] = useState(false);

    const userCanSeeSection = (sectionPermissions) => {
        if (!token) {
            return sectionPermissions.includes('guest');
        }
        return sectionPermissions.some(permission => roles.includes(permission));
    };
    const handleAddOptionChange = (event) => {
        setAddOption(event.target.value);
    };
    const handleOpenModalEdit = async () => {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${wish.id}`;
        const data = await fetchWithToken(apiUrl);
        setUpdatedWish(data);
        setEditMode(true)

    };
    const handleOpenResponsableModal = (id) => {
        setSelectedFormationId(id);
        setOpenResponsableModal(true);
    };

    const handleCloseResponsableModal = () => {
        setOpenResponsableModal(false);
        setSelectedFormationId(null);
    };

    const exportWaitList = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/fomrationsadmin/waitlist/${wish.id}/export`;

            // Exemple de données à envoyer au backend (formData)
            const formData = {};

            // Effectuer la requête et récupérer la réponse complète (pas seulement le blob)
            const response = await fetchWithToken(apiUrl, "POST", formData, false, "RESPONSE");

            // Vérifier si la réponse est correcte
            if (!response.ok) {
                throw new Error('Erreur lors de la récupération du fichier.');
            }

            // Récupérer le blob depuis la réponse
            const blob = await response.blob();

            // Récupérer l'en-tête Content-Disposition
            const contentDisposition = response.headers.get('Content-Disposition');
            console.log(response.headers)
            // Extraire le nom de fichier depuis l'en-tête (si présent)
            let fileName = 'export.xlsx'; // Nom par défaut
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
                if (fileNameMatch.length > 1) {
                    fileName = fileNameMatch[1];
                }
            }
            // Créer un lien de téléchargement et déclencher le téléchargement du fichier
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();

            // Nettoyage après téléchargement
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Erreur lors de l\'export en PDF :', error);
        }
    };


    const handleOpenQuestionnaireModal = (id) => {
        setSelectedFormationId(id);
        setOpenQuestionnaireModal(true);
    };

    const handleCloseQuestionnaireModal = () => {
        setOpenQuestionnaireModal(false);
        setSelectedFormationId(null);
    };

    const handleChangeResponsable = (event) => {
        setSelectedResponsable(event.target.value);
    };

    const handleChangeQuestionnaire = (event) => {
        setSelectedQuestionnaire(event.target.value);
    };

    const handleSearchToggle = () => {
        setSearchOpen(!searchOpen);
    };

    const handleOpenFormation = (wish) => {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${wish.id}/open`;
        const data = fetchWithToken(apiUrl).then(data => {
            setUpdatedWish(prevWish => ({
                ...prevWish,
                status: "open"
            }));
        });
    };

    const handleCloseMoulinetteFormation = (wish) => {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${wish.id}/closemoulinette`;
        const data = fetchWithToken(apiUrl).then(data => {
            setUpdatedWish(prevWish => ({
                ...prevWish,
                status: "selected"
            }));
        });
    };

    const handleSelectQuestionnaire = async (formationId, questionnaireId) => {
        // Logique pour associer le questionnaire sélectionné à la formation
        // Exemple d'appel API:
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${formationId}/assign-questionnaire`;
        await fetchWithToken(apiUrl, 'POST', { questionnaireId });
        handleCloseQuestionnaireModal();
    };

    const handleListUser = async (id) => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/fomrationsadmin/user/${wish.id}`;
            const data = await fetchWithToken(apiUrl);

            setUsers(data);
            setOpenModalList(true)
        } catch (error) {
            console.error('erreur avec handleListUser', error);
        }

    };
    const handleListUserWait = async (id) => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/fomrationsadmin/waitlist/${wish.id}`;
            const data = await fetchWithToken(apiUrl);

            setUsersWait(data);
            setOpenModalListWait(true)
        } catch (error) {
            console.error('erreur avec handleListUserWait', error);
        }

    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic
    };

    const handleAddTrainee = (newuser, function_id) => {
        // Handle add trainee logic
        requestConfirm(t("CONFIRMATION_ADD_STAGIAIRE"), async () => {
            try {

                var data = {
                    type: addOption
                }
                const apiUrl = `${process.env.REACT_APP_API_URL}/api/fomrationsadmin/user/${wish.id}/${newuser.id}/${function_id}/force`;
                await fetchWithToken(apiUrl, "POST", data);
                handleListUser();
                setSearchOpen(false)
            } catch (error) {
                console.error('Erreur lors de la récupération des tâches:', error);
                return [];
            }
        });
    };

    const handleLaunchMoulinetteValidation = async () => {
        try {
            var data = {
                type: "validerliste"
            }
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${wish.id}/moulinette`;
            await fetchWithToken(apiUrl, "POST", data);

            handleListUser();
        } catch (error) {
            console.error('Erreur lors du lancement de la moulinette:', error);
        }
    };
    const handleLaunchMoulinetteCanceln = async () => {
        try {
            var data = {
                type: "validerliste"
            }
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${wish.id}/cancelmoulinette`;
            await fetchWithToken(apiUrl, "POST", data);

            handleListUser();
        } catch (error) {
            console.error('Erreur lors du lancement de la moulinette:', error);
        }
    };
    const handleLaunchMoulinette = async (place, type, specialities, functionCodes) => {
        try {
            if ((place && type)) {
                var data = {
                    place,
                    type,
                    specialities,
                    functionCodes

                }
                const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${wish.id}/moulinette`;
                const { jobId } = await fetchWithToken(apiUrl, "POST", data);
            }
        } catch (error) {
            console.error('Erreur lors du lancement de la moulinette:', error);
        }
    };

    const handleChange = (e) => {
        const { name, checked } = e.target;
        setFilters((prevFilters) => ({
            ...prevFilters,
            status: {
                ...prevFilters.status,
                [name]: checked,
            },
        }));
    };

    const handleChangeHide = (e) => {
        const { name, checked } = e.target;
        setFilters({
            ...filters,
            hideInWait: checked,
        });
    };
    const handleCodeSycomoreChange = (e) => {
        setFilters({
            ...filters,
            codeSycomore: e.target.value,
        });
    };
    const isVisible = ['open', 'selecting'].includes(updatedWish.status);
    const isSelecting = ['selected', 'selecting'].includes(updatedWish.status);
    const hasQuestionaitre = updatedWish.questionaire_id ? true : false;
    const level = LevelOptions.find((branch) => wish.level === branch.key)
    const branch = branchOptions.find((branch) => wish.branch === branch.key)

    const applyFilters = (user) => {
        const userStatus = user.status || 'WAIT';

        // Check if user status matches any of the selected statuses
        const isStatusSelected = Object.values(filters.status).some(value => value);
        const statusMatch = isStatusSelected ? Object.keys(filters.status).some(status => filters.status[status] && status === userStatus) : true;
        // Check if code Sycomore matches
        const codeSycomoreMatch = filters.codeSycomore ? user.user_code_sycomore.includes(filters.codeSycomore) : true;
        const hideMtach = filters.hideInWait ? user.other_inscription_wait_validation == "No" : true;
        return statusMatch && codeSycomoreMatch && hideMtach;
    };

    return (
        <>
            <Card className='cep-item' sx={{
                maxWidth: 345,
                margin: 2,
                textAlign: 'center',
                border: '2px solid #ccc',
                borderRadius: '10px',
                overflow: 'visible'
            }}>
                <CardMedia
                    component="img"
                    sx={{ width: '68px', height: '68px', margin: '-25px auto 10px', zIndex: 1, position: 'relative' }}
                    image={branch.logo}

                />
                <CardContent>
                    <Typography id='cep-title' variant="h5" component="div">{level?.label} {branch?.label}</Typography>
                    <Typography sx={{ fontFamily: 'Ubuntu', fontWeight: "bold", fontSize: "16px" }} variant="h6"
                        component="div">{wish.nom}</Typography>
                    <Typography sx={{ fontFamily: 'Ubuntu', fontWeight: "bold", fontSize: "16px" }} variant="h6"
                                component="div">Code Azimuth : {wish.id}</Typography>
                    <div id='cep-date'>
                        <div>du {formatDate(wish.date)}</div>
                        <div>au {formatDate(wish.end_date)}</div>
                    </div>
                    <div id='cep-people-count'>
                        <div>{t("NBR_VOEUX_IN_THIS_FORM", wish.user_count)}</div>
                        <div>{t("NBR_VALIDATE_IN_THIS_FORM", wish.user_count_validate)}</div>
                        <div>{t("NBR_IN_WAIT_IN_THIS_FORM", wish.user_count_wait_validation)}</div>

                    </div>


                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px', textAlign: "left"}}>
                        {isAdministrator && wish.close == 0  && (
                            <Button className='azimuth-btn' variant="contained" startIcon={<CircularIcon />} sx={{ width: '100%', textAlign: 'left' }}
                                onClick={handleOpenModalEdit}>Modifier la formation</Button>
                        )}
                        <Button className='azimuth-btn' variant="contained" onClick={() => handleListUser()} startIcon={<PeopleAltRounded />}
                            sx={{ width: '100%' }}>{t('BUTTON_SEE_REGISTER')}</Button>
                        {wish.level != "cameleon" && (
                        <Button className='azimuth-btn' variant="contained" onClick={() => handleListUserWait()} startIcon={<PauseCircleOutlineRounded />}
                            sx={{ width: '100%' }}>{t('BUTTON_WAIT_LIST')}</Button>
                        )}
                        {isAdministrator  && wish.close == 0 && (
                            <Button className='azimuth-btn' variant="contained" onClick={() => handleOpenQuestionnaireModal(wish.id)}
                                startIcon={<ListIcon />}
                                sx={{ width: '100%' }}>{t('BUTTON_CHOOSE_FORM')}</Button>
                        )}

                        {isAdministrator && wish.status === "draft" && wish.close == 0  && (
                            <Button className='azimuth-btn' variant="contained" onClick={() => handleOpenFormation(wish)}
                                startIcon={<ListIcon />}
                                sx={{ width: '100%' }}>{t("BUTTON_OPEN_FORMATION")}</Button>
                        )}
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
                            {isAdministrator &&  wish.level != "cameleon"   && wish.close == 0 && (
                                <Button className='azimuth-btn' variant="contained" onClick={() => setOpenMoulinetteDialog(true)}
                                    startIcon={<RocketLaunchRounded />} sx={{ width: '100%' }}>
                                    {t("BUTTON_START_MOULINETTE")}
                                </Button>
                            )}
                            {isAdministrator && wish.status === "selecting" && (
                                <Button className='azimuth-btn' variant="contained" onClick={() => handleCloseMoulinetteFormation(wish)}
                                    startIcon={<CloseRounded />}
                                    sx={{ width: '100%' }}>{t("BUTTON_CLOSE_FORMATION_MOULINETTE")}
                                </Button>
                            )}
                        </div>
                    </Box>
                </CardContent>
            </Card>

            <FormationForm
                open={editMode}
                onClose={() => setEditMode(false)}
                onAdd={addFormation}
                initialData={updatedWish}
                functionOptions={functionOptions}
                LevelOptions={LevelOptions}
                questionnaires={questionnaires}
                branchOptions={branchOptions}
            />

            <Dialog open={openQuestionnaireModal} onClose={handleCloseQuestionnaireModal}>
                <DialogTitle>{t("TITLE_SELECT_QUESTIONNAIRE")}</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth>
                        <InputLabel>{t("LABEL_QUESTIONNAIRE")}</InputLabel>
                        <Select
                            value={selectedQuestionnaire}
                            label={t("LABEL_QUESTIONNAIRE")}
                            onChange={handleChangeQuestionnaire}
                            displayEmpty={false}
                        >
                            {questionnaires.map((questionnaire) => (
                                <MenuItem key={questionnaire.id} value={questionnaire.id}>
                                    {questionnaire.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseQuestionnaireModal} color="primary">
                        {t("BUTTON_CANCEL")}
                    </Button>
                    <Button onClick={() => handleSelectQuestionnaire(selectedFormationId, selectedQuestionnaire)}
                        color="primary">
                        {t("BUTTON_SELECT")}
                    </Button>
                </DialogActions>
            </Dialog>
            <MoulinetteModal openMoulinetteDialog={openMoulinetteDialog}
                setOpenMoulinetteDialog={setOpenMoulinetteDialog}
                handleLaunchMoulinette={handleLaunchMoulinette}
                launchAnimation={launchAnimation}
                setLaunchAnimation={setLaunchAnimation}
            />

            {openModalList && (
                <Dialog open={openModalList} onClose={() => setOpenModalList(false)} maxWidth="lg" fullWidth>
                    <DialogContent>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={3}>
                                    <FormGroup>
                                        {statuses.map((option) => (
                                            <FormControlLabel
                                                key={option.value}
                                                control={
                                                    <Checkbox
                                                        checked={filters.status[option.value]}
                                                        onChange={handleChange}
                                                        name={option.value}
                                                    />
                                                }
                                                label={option.label}
                                            />
                                        ))}
                                    </FormGroup>
                                    <FormGroup>
                                        <FormControlLabel
                                            key="hideInWait"
                                            control={
                                                <Checkbox
                                                    checked={filters.hideInWait}
                                                    onChange={handleChangeHide}
                                                    name={"hideInWait"}
                                                />
                                            }
                                            label={"Cacher en attente sur une autre formation"}
                                        />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        fullWidth
                                        label="Filtre par code Sycomore"
                                        name="codeSycomore"
                                        value={filters.codeSycomore}
                                        onChange={handleCodeSycomoreChange}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    {isAdministrator  && wish.close == 0   && (
                                        <>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                sx={{height: '40px'}}  // Définir une hauteur fixe

                                                onClick={() => setSearchOpen(true)}
                                            >
                                                {t("BTN_ADD_STAGIARE")}
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                sx={{height: '40px'}}  // Définir une hauteur fixe

                                                onClick={handleLaunchMoulinetteValidation}
                                            >
                                                {t("VALID_MOULINETTE_PROPAL")}
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                sx={{height: '40px'}}  // Définir une hauteur fixe

                                                onClick={handleLaunchMoulinetteCanceln}
                                            >
                                                {t("EFFACER_PROPOSITION")}
                                            </Button>
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        </form>
                        <Grid container spacing={2} sx={{ mt: 2 }}>
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Code Sycomore</TableCell>
                                                <TableCell>Nom</TableCell>
                                                <TableCell>Structure</TableCell>
                                                <TableCell>fonction</TableCell>
                                                <TableCell>Date de fin de validité</TableCell>
                                                <TableCell>Statut</TableCell>
                                                <TableCell>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {users && users.filter(applyFilters).map((user, index) => (
                                                <StagiareCardAdmin
                                                    key={index} index={index}
                                                    user={user}
                                                    isAdministrator={isAdministrator}
                                                    refreshUser={handleListUser} formation={wish}
                                                />
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            )}
            {openModalListWait && (
                <Dialog open={openModalListWait} onClose={() => setOpenModalListWait(false)} maxWidth="lg" fullWidth>
                    <DialogContent>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>

                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        fullWidth
                                        label="Filtre par code Sycomore"
                                        name="codeSycomore"
                                        value={filters.codeSycomore}
                                        onChange={handleCodeSycomoreChange}
                                        variant="outlined"
                                    />
                                </Grid>

                                {isAdministrator && (
                                    <button onClick={(e) => exportWaitList()}>Exporter</button>
                                )}
                            </Grid>
                        </form>
                        <Grid container spacing={2} sx={{ mt: 2 }}>
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Code Sycomore</TableCell>
                                                <TableCell>Prénom</TableCell>
                                                <TableCell>Nom</TableCell>
                                                <TableCell>Structure</TableCell>
                                                <TableCell>fonction</TableCell>

                                                <TableCell>Statut</TableCell>
                                                <TableCell>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {usersWait && usersWait.filter(applyFilters).map((user, index) => (
                                                <StagiareCardAdmin key={index} user={user} index={index}
                                                    isAdministrator={isAdministrator}
                                                    refreshUser={handleListUser} formation={wish}
                                                    waitList={1} />
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            )}
            <Dialog open={searchOpen} onClose={() => setSearchOpen(false)} aria-labelledby="search-dialog-title">
                <DialogTitle id="search-dialog-title">{t("SEARCH")}</DialogTitle>

                <DialogContent>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">{t('OPTION')}</FormLabel>
                        <RadioGroup
                            aria-label="add-option"
                            name="add-option"
                            value={addOption}
                            onChange={handleAddOptionChange}
                        >
                            <FormControlLabel
                                value="withoutValidate"
                                control={<Radio/>}
                                label={t('AJOUTER_SANS_VALIDER')}
                            />
                            <FormControlLabel
                                value="withValidate"
                                control={<Radio/>}
                                label={t('AJOUTER_ET_VALIDER')}
                            />
                        </RadioGroup>
                    </FormControl>
                    <SearchModal onClick={handleAddTrainee} functionMode={1}/>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default FormationAdminItem;
