import {useContext, useState} from 'react';
import {useAuth} from '../Context/AuthContext';

export function useFetchWithToken() {
    const {token, logout, currentFunction, functionOptions} = useAuth()
    const fetchWithToken = async (url, method = 'POST', body = null, upload, type = "JSON") => {
        try {

            const chefFunction = functionOptions.find(func => func.id == currentFunction);


            let headers = {};
            switch (method) {
                case  "POST":
                case "PUT":
                case 'DELETE':
                    if (body == null) {
                        body = {};
                    }
            }
            if (token) {


                if (upload) {
                    headers = {
                        'Authorization': `Bearer ${token}`,
                        //  'Content-Type': 'multipart/form-data',
                    };
                } else {
                    headers = {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',

                    };
                }
            }else {
                headers = {
                    'Content-Type': 'application/json',

                };
            }

            const config = {
                method,
                headers,
            };

            if (body) {
                if (chefFunction) {
                    if (upload) {
                        body.append('function_id', chefFunction.id);
                    } else {
                        body.function_id = chefFunction.id;

                    }
                }

                if (upload) {
                    config.body = (body);
                } else {
                    config.body = JSON.stringify(body);

                }

            }

            const response = await fetch(url, config);

            if (!response.ok) {
                // Gestion des erreurs spécifiques en fonction du status
                switch (response.status) {
                    case 401: // Non autorisé
                        logout();
                        break;
                    case 403: // Interdit
                        throw new Error('Accès refusé');
                    default:
                        const errorResponse = await response.json();
                        throw new Error(JSON.stringify(errorResponse));

                }
            }
            let data = null;
            switch (type) {
                case "JSON":
                    data = await response.json();
                    break
                case "BLOB":
                    data = await response.blob();
                    break;
                case "RESPONSE":
                    console.log(response)
                    data = response;
                    break;
                case "TEXT":
                    data = await response.text();
                    break;

            }

            return data;

        } catch (error) {
            throw error;
        }
    };

    return fetchWithToken;
}
