import DerogationItem from "../composant/Wish/DerogationItem";
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle, Pagination,
    Table, TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField, Typography
} from "@mui/material";
import {formatDateHour} from "../Utils/functionTransforms";
import React, {useEffect, useState} from "react";
import {useFetchWithToken} from "../hooks/useFetchWithToken";

const UserLog = ({selectedPersonne, dialogOpenLogs, setDialogOpenLogs}) => {
    const [filterDateFrom, setFilterDateFrom] = useState("");
    const [filterDateTo, setFilterDateTo] = useState("");
    const [filterEventType, setFilterEventType] = useState("");
    const [logPage, setLogPage] = useState(1); // Pagination des logs utilisateur
    const fetchWithToken = useFetchWithToken();
    const [page, setPage] = useState(1);
    const [logs, setLogs] = useState([]); // Logs utilisateur
    const [totalLogPages, setTotalLogPages] = useState(1); // Total des pages de logs


    const loadUserLogs = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/admin/user/log?page=${logPage}`;
            const body = {
                user: selectedPersonne.id,
                dateFrom: filterDateFrom || null,
                dateTo: filterDateTo || null,
                eventType: filterEventType || null,
            };
            const data = await fetchWithToken(apiUrl, 'POST', body);
            setLogs(data.logs);
            setTotalLogPages(data.totalPages);
        } catch (error) {
            console.error('Erreur lors du chargement des logs:', error);
        }
    };
    useEffect(() => {
        if (dialogOpenLogs) {
            loadUserLogs();
        }
    }, [dialogOpenLogs, logPage]);

    return (
        <Dialog open={dialogOpenLogs} onClose={() => setDialogOpenLogs(false)} fullWidth maxWidth="md">
            <DialogTitle>Journal de l'utilisateur</DialogTitle>

            <DialogContent>
                <>
                    <Box display="flex" gap={2} marginBottom={2}>
                        <TextField
                            type="date"
                            label="Date début"
                            value={filterDateFrom}
                            onChange={(e) => setFilterDateFrom(e.target.value)}
                            InputLabelProps={{shrink: true}}
                        />
                        <TextField
                            type="date"
                            label="Date fin"
                            value={filterDateTo}
                            onChange={(e) => setFilterDateTo(e.target.value)}
                            InputLabelProps={{shrink: true}}
                        />
                        <TextField
                            select
                            label="Type d'événement"
                            value={filterEventType}
                            onChange={(e) => setFilterEventType(e.target.value)}
                            SelectProps={{native: true}}
                        >
                            <option value="">Tous</option>
                            <option value="sendEmail">Email envoyé</option>
                            <option value="validateFormation">Formation validée</option>
                            <option value="RefuseFormation">Formation refusée</option>
                            <option value="AddWish">Ajout de voeu</option>
                            <option value="removeWish">Suppression de voeu</option>
                        </TextField>
                        <Button variant="contained" color="primary" onClick={() => loadUserLogs()}>
                            Appliquer les filtres
                        </Button>
                    </Box>
                    {logs.length > 0 ? (

                        <>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Événement</TableCell>
                                        <TableCell>Détails</TableCell>
                                        <TableCell>Auteur</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {logs.map((log) => (
                                        <TableRow key={log.id}>
                                            <TableCell>{formatDateHour(log.date_add)}</TableCell>
                                            <TableCell>{log.event_code}</TableCell>
                                            <TableCell>
                                                {log.event_code === 'sendEmail' && (
                                                    <>
                                                        Sujet : {log.data.subject} <br/>
                                                        Destinataire : {log.data.to}
                                                        <div dangerouslySetInnerHTML={{__html: log.data.htmlMessage}}/>
                                                    </>
                                                )}
                                                {log.event_code === 'refuseDerogation' || log.event_code === 'validateDerogation' && (
                                                    <>
                                                        Avis : {log.data.comment} <br/>
                                                        Date du voeux : {log.data.requestDate} <br/>
                                                        Type de formation : {log.data.requestLevel} <br/>
                                                        Branche : {log.data.requestBranch}
                                                    </>
                                                )}

                                                {log.event_code === 'RefuseFormation' && (
                                                    <>
                                                        Formation refusée : {log.data.formationNom} <br/>
                                                        Date de la formation : {log.data.requestDate} <br/>
                                                        Type de formation : {log.data.requestLevel} <br/>
                                                        Branche : {log.data.requestBranch}
                                                    </>
                                                )}

                                                {log.event_code === 'AddWish' && (
                                                    <>
                                                        Ajout d'un voeu : <br/>
                                                        Branche : {log.data.requestBranch} <br/>
                                                        Date : {log.data.requestDate} <br/>
                                                        Niveau : {log.data.requestLevel} <br/>
                                                        Spécialité : {log.data.requestSpeciality.length > 0 && (
                                                        <ul>
                                                            {log.data.requestSpeciality.map((speciality, index) => (
                                                                <li key={index}>
                                                                    {speciality === 'nospecialite' ? 'no_preference' : speciality}
                                                                </li>
                                                            ))}
                                                        </ul>

                                                    )}
                                                    </>
                                                )}
                                                {log.event_code === 'removeWish' && (
                                                    <>
                                                        Suppression d'un voeu : <br/>
                                                        Date : {log.data.requestDate} <br/>
                                                        Niveau : {log.data.requestLevel} <br/>
                                                        Spécialité : {log.data.requestSpeciality.length > 0 && (
                                                        <ul>
                                                            {log.data.requestSpeciality.map((speciality, index) => (
                                                                <li key={index}>
                                                                    {speciality.type === 'nospecialite' ? 'no_preference' : speciality.type}
                                                                </li>
                                                            ))}
                                                        </ul>

                                                    )}
                                                    </>
                                                )}

                                                {log.event_code === 'validateFormation' && (
                                                    <>
                                                        Formation validée : {log.data.formationNom} <br/>
                                                        Formation validée : {log.data.formationNom} <br/>
                                                        Date de la formation : {log.data.requestDate} <br/>
                                                        Type de formation : {log.data.requestLevel} <br/>
                                                        Branche : {log.data.requestBranch}
                                                    </>
                                                )}

                                            </TableCell>
                                            <TableCell>
                                                {log.writer_id !== log.user_id
                                                    ? `${log.writer_lastname} ${log.writer_firstname}`
                                                    : selectedPersonne.firstname + "  " + selectedPersonne.lastname}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <Pagination
                                count={totalLogPages}
                                page={page}
                                onChange={(event, value) => setPage(value)}
                                className="pagination-container"
                                style={{margin: '20px 0', display: 'flex', justifyContent: 'center'}}
                            />
                        </>
                    ) : (
                        <Typography>Aucun log trouvé.</Typography>
                    )}

                </>
            </DialogContent>
        </Dialog>

    )

}

export default UserLog;
