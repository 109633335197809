import React, { useState, useEffect, useCallback } from 'react';
import {
    Container,
    Typography,
    TextField,
    Button,
    Divider,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Dialog,
    DialogContent
} from '@mui/material';

import { useFetchWithToken } from '../hooks/useFetchWithToken';
import withAdminRole from "../hoc/withAdminRole";
import './styles/TextEditor.css';
import useTexts from '../hooks/useTexte';

const ForeignPage = () => {
    const [etrangers, setEtrangers] = useState([]);
    const [dialogOpenAdd, setDialogOpenAdd] = useState(false);
    const [dialogOpenEdit, setDialogOpenEdit] = useState(false);
    const [formValues, setFormValues] = useState({ email: '', nom: '', prenom: '', telephone: '' });
    const [currentEtrangerId, setCurrentEtrangerId] = useState(null);

    const fetchWithToken = useFetchWithToken();
    const { t } = useTexts();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleAddPersonne = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/user/etranger`;
            await fetchWithToken(apiUrl, "POST", formValues);
            setFormValues({ email: '', nom: '', prenom: '', telephone: '' });
            setDialogOpenAdd(false);
            getEtranger();
        } catch (error) {
            console.error("Error while adding foreign user: ", error);
        }
    };

    const handleEditPersonne = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/user/etranger/${currentEtrangerId}`;
            await fetchWithToken(apiUrl, "POST", { id: currentEtrangerId, ...formValues });
            setFormValues({ email: '', nom: '', prenom: '', telephone: '' });
            setDialogOpenEdit(false);
            setCurrentEtrangerId(null);
            getEtranger();
        } catch (error) {
            console.error("Error while updating foreign user: ", error);
        }
    };

    const handleOpenEditDialog = (etranger) => {
        setFormValues({
            email: etranger.email || '',
            nom: etranger.lastname || '',
            prenom: etranger.firstname || '',
            telephone: etranger.phone || ''
        });
        console.log(etranger)
        setCurrentEtrangerId(etranger.id);
        setDialogOpenEdit(true);
    };

    const getEtranger = useCallback(async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/user/getEtrangers`;
            const data = await fetchWithToken(apiUrl, "POST", {});
            setEtrangers(data);
        } catch (error) {
            console.error(error);
        }
    }, [fetchWithToken]);

    useEffect(() => {
        getEtranger();
    }, []);

    const handleDeleteEtranger = async (id) => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/user/deleteEtranger`;
            await fetchWithToken(apiUrl, 'POST', { function_to_delete: id });
            getEtranger();
        } catch (error) {
            console.error('Error while deleting foreign user: ', error);
        }
    };

    return (
        <Container className='textEditor-container'>
            <Typography variant="h4" gutterBottom style={{ textAlign: 'center', color: 'black', margin: '16px 0' }}>
                {t("ADD_ETRANGER")}
            </Typography>
            <div className='add-text-container desktop-restrict'>
                <Button
                    variant="contained"
                    color="success"
                    onClick={() => setDialogOpenAdd(true)}
                    id='add-text-button'
                >
                    {t("BUTTON_ADD_ETRANGER")}
                </Button>
            </div>
            <Divider />

            <TableContainer sx={{ maxHeight: '100%' }} className='desktop-restrict'>
                <Table stickyHeader size={'small'}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Code</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Nom</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Prénom</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Email</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Téléphone</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {etrangers.map(p => (
                            <TableRow key={p.id}>
                                <TableCell>{p.code_sycomore}</TableCell>
                                <TableCell align='left'>{p.lastname}</TableCell>
                                <TableCell align='left'>{p.firstname}</TableCell>
                                <TableCell align='left'>{p.email}</TableCell>
                                <TableCell align='left'>{p.phone}</TableCell>
                                <TableCell>
                                    <div className='actions-button'>
                                        <button
                                            onClick={() => handleOpenEditDialog(p)}
                                            className='azimuth-button'
                                        >
                                            {t("BUTTON_EDIT")}
                                        </button>
                                        <button
                                            onClick={() => handleDeleteEtranger(p.id)}
                                            className='azimuth-button'
                                        >
                                            {t("BUTTON_DELETE")}
                                        </button>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog open={dialogOpenAdd} onClose={() => setDialogOpenAdd(false)}>
                <DialogContent>
                    <Typography variant="h6" gutterBottom>
                        {t("ADD_NEW_ETRANGER")}
                    </Typography>
                    <TextField
                        fullWidth
                        label="Email"
                        name="email"
                        value={formValues.email}
                        onChange={handleInputChange}
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        label="Nom"
                        name="nom"
                        value={formValues.nom}
                        onChange={handleInputChange}
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        label="Prénom"
                        name="prenom"
                        value={formValues.prenom}
                        onChange={handleInputChange}
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        label="Téléphone"
                        name="telephone"
                        value={formValues.telephone}
                        onChange={handleInputChange}
                        margin="normal"
                    />
                    <div style={{ marginTop: '16px', textAlign: 'right' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddPersonne}
                            style={{ marginRight: '8px' }}
                        >
                            {t("BUTTON_SAVE")}
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => setDialogOpenAdd(false)}
                        >
                            {t("BUTTON_CANCEL")}
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog open={dialogOpenEdit} onClose={() => setDialogOpenEdit(false)}>
                <DialogContent>
                    <Typography variant="h6" gutterBottom>
                        {t("EDIT_ETRANGER")}
                    </Typography>

                    <TextField
                        fullWidth
                        label="Nom"
                        name="nom"
                        value={formValues.nom}
                        onChange={handleInputChange}
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        label="Prénom"
                        name="prenom"
                        value={formValues.prenom}
                        onChange={handleInputChange}
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        label="Téléphone"
                        name="telephone"
                        value={formValues.telephone}
                        onChange={handleInputChange}
                        margin="normal"
                    />
                    <div style={{ marginTop: '16px', textAlign: 'right' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleEditPersonne}
                            style={{ marginRight: '8px' }}
                        >
                            {t("BUTTON_SAVE")}
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => setDialogOpenEdit(false)}
                        >
                            {t("BUTTON_CANCEL")}
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </Container>
    );
};

export default withAdminRole(ForeignPage);
