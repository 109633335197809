import {    Button} from "@mui/material";
import React from "react";
import {useFetchWithToken} from "../../hooks/useFetchWithToken";
import useTexts from "../../hooks/useTexte";
import Satisfaction from "../Satisfaction/Satisfaction";

function QuestionairePostFormationForm({
                                           link,
                                           user_id,
                                           formation_id,
                                           setSubmissionError,
                                           setIsSubmitted,
                                           formData,
                                           formState,
                                           setFormState
                                       }) {
    const fetchWithToken = useFetchWithToken();
    const {t} = useTexts();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!allFieldsFilled()) {
            setSubmissionError(t('PLEASE_FILL_ALL_FIELDS'));
            return;
        }else{
            setSubmissionError("")
        }

        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/form/getformeval/${link}/${user_id}/${formation_id}/save`;
            const body = {formData: formState};
            await fetchWithToken(apiUrl, "POST", body);
            setIsSubmitted(true);
        } catch {
            setSubmissionError(t('FORM_SUBMISSION_ERROR'));
        }
    }
    const allFieldsFilled = () => {
        return Object.keys(formData.sections).every(sectionIndex =>
            formData.sections[sectionIndex].fields.every(field => {
                if (field.type === "textarea" || field.type === "text") {
                    return true;
                }
                return formState.hasOwnProperty(`${field.name}`);
            })
        );
    };
    return (

        <>
            <Satisfaction formData={formData} formState={formState} setFormState={setFormState} />
            <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSubmit}
                className="azimuth-btn"
            >
                {t("VALIDATE_POST_QUESTIONNAIRE")}
            </Button>
        </>
    );


}

export default QuestionairePostFormationForm;
