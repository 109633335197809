import React, {useState, useEffect} from 'react';
import {
    Button,
    TextField,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography, Box, CircularProgress, Select
} from '@mui/material';
import {calculateEndDate, formatDate} from '../../Utils/functionTransforms';
import {useFetchWithToken} from "../../hooks/useFetchWithToken";
import useTexts from "../../hooks/useTexte";
import {makeStyles} from "tss-react/mui";
import {style} from "../../Utils/style";
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import {useSystem} from "../../Context/System";
import {cepBranchType} from "../../Utils/CepBranchType"
import {useAuth} from "../../Context/AuthContext";

const months = [
    {value: 8, label: 'Septembre'},
    {value: 9, label: 'Octobre'},
    {value: 10, label: 'Novembre'},
    {value: 11, label: 'Décembre'},
    {value: 0, label: 'Janvier'},
    {value: 1, label: 'Février'},
    {value: 2, label: 'Mars'},
    {value: 3, label: 'Avril'},
    {value: 4, label: 'Mai'},
    {value: 5, label: 'Juin'},
    {value: 6, label: 'Juillet'},
    {value: 7, label: 'Août'},
];

const useStyles = makeStyles()((theme) => ({
    dialogTitle: {
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: '18px', // Adjust font size for mobile
        color: style.bleu1,
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: '15px', // Reduce gap for mobile
        padding: '10px', // Add padding for mobile
    },
    menuItem: {
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        gap: '10px'
    },
    select: {
        '& .MuiSelect-select': {
            fontWeight: 'bold',
        },
    },
    fieldLabel: {
        fontWeight: 'bold',
        fontSize: '12px', // Adjust font size for mobile
        color: '#0056b3',
    },
    fieldValue: {
        fontSize: '14px', // Adjust font size for mobile
        color: '#333',
    },
    actions: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px', // Reduce gap for mobile
        padding: '10px', // Add padding for mobile
    },
    button: {
        padding: '8px 16px', // Adjust padding for mobile
        borderRadius: '20px',
        border: '2px solid #0056b3',
        color: '#0056b3',
        backgroundColor: 'white',
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#0056b3',
            color: 'white',
        },
    },
    submitButton: {
        backgroundColor: '#0056b3',
        color: 'white',
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#003f7f',
        },
    },
    openSession: {
        backgroundColor: '#e0f7fa',
        fontWeight: 'bold',
        color: 'green',
    },
    noSession: {
        backgroundColor: 'white',
        fontWeight: 'bold',
        color: 'black',
    },
}));

const AddWishForm = ({open, onClose, onAdd, functionOptions, LevelOptions, branchOptions}) => {
    const fetchWithToken = useFetchWithToken();
    const {t} = useTexts();
    const {openMonth, currentYear:systemYear} = useSystem()
    const {currentFunction} = useAuth();

    const {classes} = useStyles();

    const [wish, setWish] = useState({
        date: '',
        function_id_2: currentFunction,
        level: '',
        branch: '',
        formation: ["no_preference"],
        cepBranchType: ["no_preference"]
    });
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());

    const [selectedFunction, setSelectedFunction] = useState(currentFunction);
    const [saturdays, setSaturdays] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState();
    const [selectedLevel, setSelectedLevel] = useState();
    const [openDates, setOpenDates] = useState([]);
    const [forceSelectMonth, setForceSelectMonth] = useState(true);
    const [freeDate, setFreeDate] = useState();
    const [choiceCep, setChoiceCep] = useState();
    const [restFormation, setResetFormation] = useState(false);

    const [restTypeFormation, setRestTypeFormation] = useState(false);
    const changeFunction = (e) => {
        setSelectedFunction(e);
        setWish(prev => ({...prev, function_id_2: e}));
    };

    const handleCepBranchTypeChange = (selectedValues) => {
        setWish(prev => ({...prev, cepBranchType: selectedValues}));
    };
    const [trainingSessions, setTrainingSessions] = useState([]);
    const [trainingSessionsDates, setTrainingSessionsDates] = useState([]);

    const getAvailableMonths = (currentMonth) => {
        const currentYear = new Date().getFullYear();
        const currentAcademicMonth = (currentMonth + 4) % 12; // Shift months by 4 to start at September (0 = Sept, 11 = Aug)

        return months.filter(month => {
            const academicMonth = (month.value + 4) % 12;
            return academicMonth >= currentAcademicMonth || (currentMonth < 8 && academicMonth <= 4);
        });
    };
    const handleFormationChange = (selectedValues) => {
        setWish(prev => ({...prev, formation: selectedValues}));
    };
    const loadFormation = async (branche, level, month) => {
        try {
            const body = {branche, level, month};
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationdates`;
            const data = await fetchWithToken(apiUrl, "POST", body);
            return data;
        } catch (error) {
            console.error(error);
        }
        return [];
    };

    useEffect(() => {
        setWish({date: '', function: '', level: '', branch: '', formation: [], cepBranchType: []});
        setSelectedMonth(undefined);
        setSelectedLevel(undefined);
        setSelectedBranch(undefined);
        setRestTypeFormation(false)
        setResetFormation(false)
        setForceSelectMonth(true);
        setTrainingSessions([]);
        setTrainingSessionsDates([]);

        setWish(prev => ({...prev, formation: []}))
        setWish(prev => ({...prev, cepBranchType: []}))
    }, [open]);
    useEffect(() => {
        if (restFormation) {
            setWish(prev => ({...prev, formation: ["no_preference"]}))
            setResetFormation(false)
        }
    }, [restFormation]);

    useEffect(() => {
        if (restTypeFormation) {
            setWish(prev => ({...prev, cepBranchType: ["no_preference"]}))
            setRestTypeFormation(false)
        }
    }, [restTypeFormation]);
    useEffect(() => {
        if (forceSelectMonth) {
            setSelectedMonth(undefined);
            setForceSelectMonth(false);
            setRestTypeFormation(false)
            setResetFormation(false)
            setTrainingSessions([]);
            setTrainingSessionsDates([]);
            setWish(prev => ({...prev, formation: []}))
            setWish(prev => ({...prev, cepBranchType: []}))
        }
    }, [forceSelectMonth]);

    const getSaturdaysOfMonth = (year, month) => {

        const dates = [];
        let date = new Date(year, month, 1);
        while (date.getDay() !== 6) {
            date.setDate(date.getDate() + 1);
        }

        while (date.getMonth() === month) {
            const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
            const formattedDate = localDate.toISOString().slice(0, 10); // Formater en YYYY-MM-DD
            dates.push(formattedDate);

            date.setDate(date.getDate() + 7);
        }

        return dates;
    };
    const getAllRelevantDates = () => {
        const filteredSaturdays = saturdays.filter(saturday => openDates.some(openDate => openDate.date === saturday.slice(5)))
        const trainingSessionDates = trainingSessions.filter(session => !filteredSaturdays.includes(session.date)).map(session => session.date); // Only get the `date` attribute from trainingSessions
        const allDates = [...new Set([...filteredSaturdays, ...trainingSessionDates])];
        return allDates.sort();
    };
    const getAdditionalInfo = (date) => {
        const session = trainingSessions.find(session => session.date === date);
        return session ? {status: 'open', text: t("HAVE_SESSION")} : {status: 'closed', text: t("NO_SESSION")};
    };

    useEffect(() => {
        if (selectedMonth !== null && selectedMonth !== undefined) {

            const currentYear = new Date().getFullYear();
            const currentDateYear = new Date().getFullYear();


            const year = selectedMonth < 8 && currentDateYear  < parseInt(systemYear.code_saison) ? currentYear + 1 : currentYear;

            const daysSamedi = getSaturdaysOfMonth(year, selectedMonth);
            setSaturdays(daysSamedi);

            loadFormation(selectedBranch, selectedLevel, selectedMonth).then(data => {
                setTrainingSessions(data.formations);
                const datesEnd = [];
                setOpenDates(data.openDates);
                if (data.formations && data.formations.length) {
                    data.formations.forEach(d => datesEnd.push({"start": d.date, "end": d.end_date}));
                }
                setTrainingSessionsDates(datesEnd);
            });
        }
    }, [selectedMonth]);

    const handleSubmit = (e) => {
        e.preventDefault();
        onAdd(wish);
        onClose();
    };

    const handleBranchChange = (newBranch) => {
        setWish(prev => ({...prev, branch: newBranch, level: '', date: '', formation: [], cepBranchType: []}));

        setSelectedBranch(newBranch);
        setSelectedLevel(undefined);
        setForceSelectMonth(true);
        setTrainingSessions([]);
        setTrainingSessionsDates([]);

        const dataBranch = branchOptions.find(option => option.key == newBranch);
        setChoiceCep(dataBranch.choicecep)
    };

    const handleDateChange = (newDate) => {
        setWish({...wish, date: newDate, formation: [], cepBranchType: []})

    };

    const handleLevelChangeNext = (newLevel) => {
        setWish(prev => ({...prev, level: newLevel, date: ''}));
        setSelectedLevel(newLevel);
        setSelectedMonth(undefined);
    };

    function findEndDate(startDate) {
        const dateRange = trainingSessionsDates.find(range => range.start === startDate);
        return dateRange ? formatDate(dateRange.end) : null;
    }

    const handleLevelChange = (newLevel) => {
        const data = LevelOptions.find(option => option.key == newLevel);
        setFreeDate(data.freedate);
        // setChoiceCep(data.)
        if (!data.freedate) {
            loadFormation(selectedBranch, newLevel).then(data => {
                setTrainingSessions(data.formations);
                const dates = [];
                const datesEnd = [];
                if (data.formations && data.formations.length) {
                    data.formations.forEach(d => dates.push(d.date));
                    data.formations.forEach(d => datesEnd.push({"start": d.date, "end": d.end_date}));
                }
                setSaturdays(dates);

                setTrainingSessionsDates(datesEnd);

                handleLevelChangeNext(newLevel);
            });
        } else {
            handleLevelChangeNext(newLevel);
            setTrainingSessions([]);
        }
    };
    if (!openMonth) {
        return <Box sx={{display: 'flex', justifyContent: 'center', p: 3}}><CircularProgress/></Box>;
    }
    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
            <form onSubmit={handleSubmit} className={classes.form}>
                <DialogTitle id="form-dialog-title">
                    <Typography component="div" className={classes.dialogTitle}>
                        {t("TEXT_ADD_WISH")}
                    </Typography>
                </DialogTitle>
                <DialogContent dividers sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
                    {functionOptions && functionOptions.length > 1 && (
                        <>
                            <Select
                                value={selectedFunction}
                                onChange={(event) => changeFunction(event.target.value)}
                                displayEmpty
                                fullWidth
                            >
                                {functionOptions.map(({fonction, id, nom, type}) => (
                                    <MenuItem key={id} value={id}>
                                        {nom} - {fonction}
                                    </MenuItem>
                                ))}
                            </Select>
                            <Typography variant="body2">{t("FUNCTION_ADD_WISH_WARNING")}</Typography>
                        </>
                    )}
                    <TextField
                        select
                        label="Branche"
                        value={wish.branch}
                        className={classes.select}
                        onChange={(e) => handleBranchChange(e.target.value)}
                        variant="outlined"
                        fullWidth
                    >
                        {branchOptions.map((option, index) => (
                            <MenuItem key={index} className={classes.menuItem}
                                      value={option.key}>{option.label}</MenuItem>
                        ))}
                    </TextField>
                    {wish.branch && (
                        <>
                            <TextField
                                select
                                label="Niveau"
                                value={wish.level}
                                className={classes.select}
                                onChange={(e) => handleLevelChange(e.target.value)}
                                variant="outlined"
                                fullWidth
                            >
                                {LevelOptions.filter(option => option.section.includes(wish.branch)).map((option, index) => (
                                    <MenuItem key={index} className={classes.menuItem}
                                              value={option.key}>{option.label}</MenuItem>
                                ))}
                            </TextField>
                            {wish.branch && wish.level && (
                                <>
                                    {freeDate && (
                                        <TextField
                                            select
                                            label={t("TEXT_LABEL_MOIS")}
                                            key={forceSelectMonth ? 1 : 0}
                                            value={selectedMonth !== undefined ? selectedMonth : ''}
                                            className={classes.select}
                                            onChange={(e) => setSelectedMonth(parseInt(e.target.value, 10))}
                                            fullWidth
                                        >
                                            {months
                                                .filter(month => openMonth.includes(month.value.toString().padStart(2, '0'))) // Filtrer par mois ouverts
                                                .map((month) => (
                                                    <MenuItem key={month.value} className={classes.menuItem}
                                                              value={month.value}>
                                                        {month.label}
                                                    </MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    )}
                                    {(typeof selectedMonth === 'number' && freeDate) && (
                                        <TextField
                                            select
                                            label={t("TEXT_COL_DATE")}
                                            value={wish.date}
                                            className={classes.select}
                                            onChange={e => handleDateChange(e.target.value)}
                                            fullWidth
                                        >
                                            {getAllRelevantDates().map((saturday) => {


                                                const info = getAdditionalInfo(saturday);
                                                return (
                                                    <MenuItem key={saturday} value={saturday}
                                                              className={`${classes.menuItem} ${info.status === 'open' ? classes.openSession : classes.noSession}`}>
                                                        <div style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'flex-start'
                                                        }}>
                                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                                {info.status === 'open' ? <EventAvailableIcon/> :
                                                                    <EventBusyIcon/>}
                                                                {t("TEXT_COL_DATE_FULL", formatDate(saturday), findEndDate(saturday) || calculateEndDate(saturday))}
                                                            </div>
                                                            <Typography variant="body2" color="textSecondary">
                                                                {info.text}
                                                            </Typography>
                                                        </div>
                                                    </MenuItem>
                                                );
                                            })}
                                        </TextField>
                                    )}
                                    {(!freeDate) && (
                                        <TextField
                                            select
                                            label={t("TEXT_COL_DATE")}
                                            value={wish.date}
                                            className={classes.select}
                                            onChange={e => setWish({...wish, date: e.target.value})}
                                            fullWidth
                                        >
                                            {saturdays.map((saturday) => {
                                                const info = getAdditionalInfo(saturday);
                                                return (
                                                    <MenuItem key={saturday} value={saturday}
                                                              className={`${classes.menuItem} ${info.status === 'open' ? classes.openSession : classes.noSession}`}>
                                                        <div style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'flex-start'
                                                        }}>
                                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                                {info.status === 'open' ? <EventAvailableIcon/> :
                                                                    <EventBusyIcon/>}
                                                                {t("TEXT_COL_DATE_FULL", formatDate(saturday), findEndDate(saturday) || calculateEndDate(saturday))}
                                                            </div>
                                                            <Typography variant="body2" color="textSecondary">
                                                                {info.text}
                                                            </Typography>
                                                        </div>
                                                    </MenuItem>
                                                );
                                            })}
                                        </TextField>
                                    )}
                                </>
                            )}

                            {(wish.branch && wish.level && wish.date && choiceCep == 1 && trainingSessions.filter(session => session.date === wish.date).length > 1) && (
                                <>
                                    <TextField
                                        select
                                        label={t("CHOISE_CEP")}
                                        className={classes.select}
                                        value={Array.isArray(wish.formation) ? wish.formation : []} // Gérer le cas où `formation` est un tableau
                                        onChange={(e) => handleFormationChange(e.target.value)} // Fonction qui gère le changement de sélection
                                        SelectProps={{
                                            multiple: true, // Activer le mode de sélection multiple
                                            renderValue: (selected) => selected.length === 0
                                                ? t("Pas de préférence") // Afficher "Pas de préférence" si aucun élément sélectionné
                                                : selected
                                                .map(id => trainingSessions.find(session => session.id === id)?.nom) // Vérifie l'existence de la session
                                                .filter(Boolean) // Supprime les valeurs "undefined"
                                                .join(', ') || t("Pas de préférence") // Affiche "Pas de préférence" si aucun élément valide
                                        }}
                                        fullWidth
                                    >
                                        {/* Option "Pas de préférence" */}
                                        <MenuItem value="no_preference"

                                                  onClick={(e) => {
                                                      setResetFormation(true)
                                                  }}
                                        >

                                            <em>{t("Pas de préférence")}</em>
                                        </MenuItem>

                                        {trainingSessions.filter(session => session.date === wish.date).map((sessionData) => (
                                            <MenuItem key={sessionData.id} value={sessionData.id}>
                                                <div>
                                                    <Typography variant="body1" fontWeight="bold">
                                                        {sessionData.lieu} {/* Affichage du lieu */}
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary">
                                                        {cepBranchType[sessionData.specalite] || 'Pas de spécialité'} {/* Affichage de la spécialité */}
                                                    </Typography>
                                                </div>
                                            </MenuItem>
                                        ))}

                                    </TextField>
                                </>
                            )}

                            {(wish.branch && wish.level && wish.date && choiceCep == 1
                                && (trainingSessions.filter(session => session.date === wish.date).length > 0
                                    || wish.formation.includes("no_preference"))


                            ) && (
                                <>
                                    <TextField
                                        select
                                        label={t("CHOISE_CEP_TYPE")}
                                        className={classes.select}
                                        value={Array.isArray(wish.cepBranchType) ? wish.cepBranchType : []} // Assure que `wish.cepBranchType` est un tableau
                                        onChange={(e) => handleCepBranchTypeChange(e.target.value)} // Handle changes in selection
                                        SelectProps={{
                                            multiple: true, // Activer le mode de sélection multiple
                                            renderValue: (selected) => selected.length === 0
                                                ? t("Pas de préférence") // Afficher "Pas de préférence" si aucun élément sélectionné
                                                : selected
                                                .map(id => cepBranchType[id]) // Vérifie l'existence du type CEP
                                                .filter(Boolean) // Supprime les valeurs "undefined"
                                                .join(', ') || t("Pas de préférence") // Affiche "Pas de préférence" si aucun élément valide
                                        }}
                                        fullWidth
                                    >
                                        {/* Option "Pas de préférence" */}
                                        <MenuItem value="no_preference"

                                                  onClick={(e) => {
                                                      setRestTypeFormation(true)
                                                  }}
                                        >

                                            <em>{t("Pas de préférence")}</em>
                                        </MenuItem>

                                        {Object.keys(cepBranchType).map((key) => (
                                            <MenuItem key={key} value={key}>
                                                {cepBranchType[key]}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </>
                            )}


                        </>
                    )}
                </DialogContent>
                <DialogActions style={{display: "flex", flexDirection: 'column', gap: '10px'}}>
                    <Button onClick={onClose} className={classes.button}>
                        <CancelIcon/> {t("BUTTON_ANNULER")}
                    </Button>
                    {wish && wish.date && wish.branch && wish.level
                        && (choiceCep == 0 || choiceCep == 1 && (wish.cepBranchType))

                        && (
                            <Button type="submit" className={classes.submitButton}>
                                <AddIcon/> {t("BUTTON_AJOUTER_WHISH")}
                            </Button>
                        )}
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default AddWishForm;
