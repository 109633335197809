import {Box, Button, Paper, Typography} from "@mui/material";
import React from "react";
import UserFunction from "./UserFunction";

const AvisBox = ({title, avis, onClick, type, isPage}) => (
    <Paper elevation={3} sx={{padding: 2}}>
        <Typography variant="subtitle1">{title}</Typography>
        <Box
            mt={1}
            p={1}
            maxHeight="150px"
            overflow="auto"
            border="1px solid #e0e0e0"
            borderRadius="4px"
            bgcolor="#f5f5f5"
        >
            <Typography variant="body1">
                {avis ? (avis.idontknow === 1 ? 'Je ne connais pas le chef' : avis.avis_avis) : 'Pas d\'avis'}
            </Typography>
        </Box>

        {/* Bouton placé sous la box */}
        {onClick && isPage && (
            <Box mt={2} display="flex" justifyContent="center">
                <Button variant="contained" onClick={() => onClick(type, avis)}>
                    Modifier
                </Button>
            </Box>
        )}
    </Paper>
);

export default AvisBox;