import {Alert, Box, Typography} from "@mui/material";
import {formatDate} from "../../Utils/functionTransforms";
import React from "react";
import useTexts from "../../hooks/useTexte";
import {branchOptions} from "../../Utils/BranchOption";
import {LevelOptions} from "../../Utils/LevelOption";

export default function QuestionairePostFormationWelcome({ user, formation, submissionError }) {
    const {t} = useTexts();
    const branch = branchOptions.find((option) => formation.section_id === option.id);
    const level = LevelOptions.find((branch) => formation.level === branch.key);
    return (
        <>
            <Box sx={{mb: 3}}>
                <Typography variant="h5" gutterBottom fontFamily={'Poppins'}>
                    {t("WELCOME_FORM", user?.firstname, user?.lastname)}
                </Typography>
                <Typography variant="body1" fontFamily={'Poppins'}>
                    {t("TEXT_FORM_INTRO", level.label, branch.label, formatDate(formation.start_date), formatDate(formation.end_date))}
                </Typography>
            </Box>
            {submissionError && (
                <Alert severity="warning" sx={{mb: 2}}>
                    {submissionError}
                </Alert>
            )}
        </>
    );
}
